import org.w3c.fetch.*
import org.w3c.workers.FetchEvent
import org.w3c.workers.InstallEvent
import org.w3c.workers.ServiceWorkerGlobalScope
import kotlin.js.Promise


external val self: ServiceWorkerGlobalScope


const val MAIN_CACHE = "mainCache"

fun main() {
	installServiceWorker()
}

fun installServiceWorker() {
	val offlineContent = arrayOf(
		"/index.html",
		"/client.js"
	)

	self.addEventListener(
		"install", { event ->
			event as InstallEvent
			console.log("Installed", event)

			event.waitUntil(self.caches.open(MAIN_CACHE).then {
				Promise.resolve(it.addAll(offlineContent).then {
					console.log("Offline cache loaded.")
				})
				it
			})
		})
	self.addEventListener("fetch", {
		val fetchEvent = it as FetchEvent
		if (fetchEvent.request.headers.has("Content-Type")) return@addEventListener

		val then = self.caches.open(MAIN_CACHE).then { cache ->
			val fetch = self.fetch(fetchEvent.request)
			fetch.then { response ->
				if (fetchEvent.request.method == "GET") {
					cache.put(fetchEvent.request, response.clone())
				}
				response
			}

		}.then { it }
		fetchEvent.respondWith(
			then
		)
//		scope.async {
//			val cache = self.caches.open(MAIN_CACHE).await()
//			try {
//				val response = self.fetch(fetchEvent.request).await()
//
//				cache.put(fetchEvent.request, response.clone()).await()
//				return@async response
//			} catch (e: Throwable) {
//				return@async self.caches.match(fetchEvent.request).await().unsafeCast<Response>()
//			}
//		}.let {
//			fetchEvent.respondWith(it.asPromise())
//		}
	}
	)

}
